<template>
  <div class="box">
    <div class="banner">
      <img src="@/img/guibi/banner.png" alt="" />
    </div>

    <div class="grid">
      <van-grid icon-size="25px" :border="false">
        <van-grid-item
          :icon="require('@/img/guibi/grid1.png')"
          text="智能匹配"
          to="measurement"
        />
        <van-grid-item
          :icon="require('@/img/guibi/grid2.png')"
          text="企业报告"
          to="companyReport"
        />
        <van-grid-item
          :icon="require('@/img/guibi/grid3.png')"
          text="我的企业"
          to="company"
        />
        <van-grid-item
          :icon="require('@/img/guibi/grid4.png')"
          text="关于我们"
          to="about"
        />
      </van-grid>
    </div>
    <div class="bank-box">
      <div class="bank-title">热门企业服务</div>
      <img
        src="@/img/guibi/tag1.png"
        class="xbanner"
        @click="to('companyReport')"
      />
      <img
        src="@/img/guibi/tag2.png"
        class="xbanner"
        @click="to('consultant')"
      />
    </div>
    <div class="tag-box">
      <div class="tag">
        <img :src="require('@/img/guibi/1.png')" class="tag-icon" />
        <div class="tag-right">
          <div class="tag-title">大数据</div>
          <div class="tag-hint">量化风控模型</div>
        </div>
      </div>
      <div class="tag">
        <img :src="require('@/img/guibi/2.png')" class="tag-icon" />
        <div class="tag-right">
          <div class="tag-title">自动化</div>
          <div class="tag-hint">企业背景报告</div>
        </div>
      </div>
      <div class="tag">
        <img :src="require('@/img/guibi/3.png')" class="tag-icon" />
        <div class="tag-right">
          <div class="tag-title">一网式</div>
          <div class="tag-hint">优质服务汇聚</div>
        </div>
      </div>
      <div class="tag">
        <img :src="require('@/img/guibi/4.png')" class="tag-icon" />
        <div class="tag-right">
          <div class="tag-title">全逻辑</div>
          <div class="tag-hint">精准服务匹配</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {};
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (localStorage.getItem("wql_token")) {
        console.log();
      } else {
        localStorage.clear();
        this.$http
          .post(url.login, { grant_type: "client_credentials" })
          .then((res) => {
            localStorage.setItem("wql_token", res.access_token);
          });
      }
    },
    to(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #fafafa;
  min-height: 100vh;
  padding-top: 10px;
  padding-bottom: 80px;
  .banner {
    width: 355px;
    height: 173px;
    margin: 0 auto;
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
  .hint {
    width: 355px;
    height: 40px;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 10px;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .hint-text {
    font-size: 12px;
    color: #666666;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .gonggao {
    flex: none;
    width: calc(355px - 50px);
    height: 40px;
    line-height: 40px;
  }
  .hint-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .grid {
    width: 355px;
    height: 100px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 10px;
  }
  .grid .van-grid-item__text text {
    font-size: 12px;
    color: #435381;
  }
  .tag-box {
    width: 355px;
    height: 130px;
    margin: 0 auto;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .tag {
    width: 50%;
    text-align: center;
    display: flex;
  }
  .tag-icon {
    width: 34px;
    height: 34px;
    margin-left: 20px;
  }
  .tag-right {
    margin-left: 10px;
    text-align: left;
  }
  .tag-title {
    font-size: 15px;
    color: #51608d;
  }
  .tag-hint {
    font-size: 10px;
    color: #8b94b3;
  }
  .product-box {
    width: 355px;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 10px;
  }
  .cell-title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .cell-val {
    font-size: 12px;
    color: #8f98b3;
  }
  .bank-box {
    width: 355px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 15px;
    text-align: center;
    .bank-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      padding: 10px;
      text-align: left;
    }
    .xbanner {
      width: 315px;
      height: 130px;
      margin: 0 auto;
      margin-top: 17px;
    }
  }
}
</style>
